.csvbox {
    width: 100%;
}

.input_container textarea {
    min-height: 100px;
}

.input_container {
    margin-bottom: 50px;
}

.input_container label {
    display: block;
    font-weight: bold;
}

.input_container input,
.input_container textarea {
    width: 100%;
}

.label_container {
    display: flex;
    align-items: baseline;
}
.label_container label {
    margin-right: 10px;
}
.label_container span {
    cursor: pointer;
    font-size: 11px;
}
.label_container span:hover {
    text-decoration: underline;
}