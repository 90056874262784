.root {
    display: flex;

}

.root main {
    margin-left: 40px;
}

.auth_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}