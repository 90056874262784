.root {
    padding: 5px;
    width: 150px;
}

.root li {

    list-style-type: none; /* Remove bullets */
    margin: 0;             /* Remove margins */
    padding: 0;            /* Remove padding */

    padding: 6px 3px;
    border-radius: 4px;
    font-size: 14px;
    white-space:nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    cursor: pointer;
}
.root li:hover {
    background-color: #eee;
    cursor: pointer;
}
.root li a {
    text-decoration: none;
}

.root .sign_out {
    font-size: 10px;
}