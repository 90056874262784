.product_details {
    margin-top: 5px;
    padding-right: 5px;
    font-size: 0.7em;
    
}

.product_list { 
    border-collapse: collapse;
}
.product_list th,
.product_list td{
    vertical-align: top;
}
.product_list tr {
    border-bottom: 1px solid #bbb;
}

.viewLogAttach {
    position: relative;
}
.viewLogContainer {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    z-index:1;
    background-color: white;
    padding: 5px;
    border-radius: 6px;
    box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
    
    max-height: calc(60vh);
    overflow-y: auto;
    margin-left: 10px;
}

.header {
    display: flex;
    align-items: center;
}
.header h1 {
    flex-grow: 1;
}
.workflows {
    display: flex;
    align-items: center;
    margin-right: 30px;
}
.workflowsTitle {
    margin-right: 20px;
}
.workflowItems {
    display: flex;
    align-items: center;
}
.workflowItems .workflowItem {
    margin-right: 10px;
    border-radius: 6px;
    padding: 3px 6px;
    background-color: #eee;
    box-shadow: 0 0 2px rgba(0,0,0,0.4);
    transition: box-shadow 350ms ease;
    cursor: pointer;
}
.workflowItems .workflowItem:hover {
    box-shadow: 0 0 8px rgba(0,0,0,0.6);
}
.workflowItems .workflowItem[data-selected='1'] {
    font-weight: bold;
}


.productsHeading {
    display: flex;
    align-items: center;
}
.productsHeading h2{
    flex-grow: 1;
}

.fact_sheet {
    font-size: 10px;
}